import type { FunctionComponent } from "react";

import { Container, StyledLogo } from "./styles";

type Props = {
  className?: string;
};

export const HeaderMyNews: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    <StyledLogo />
  </Container>
);

HeaderMyNews.displayName = "HeaderMyNews";
